import React, { useRef, useState } from 'react'
import logo from '..//assets/logo.png';
import ios from '..//assets/ios.png'
import android from '..//assets/android.png'
import Car from '../assets/car.png'
import secondCrousel from '../assets/secondCrouselImg.png'
import awsm1 from '../assets/awsome_feature1.png'
import awsm2 from '../assets/awsome_feature2.png'
import awsm3 from '../assets/awsome_feature3.png'
import mobile from '../assets/mobile.png'
import avatargirl from '../assets/avatargirl.png'
import { MenuOutlined } from '@ant-design/icons';
import './Landing.css'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { Button, Carousel, Col, Drawer, Form, Image, Input, Row } from 'antd';
import { Link } from 'react-scroll';
import solidAppMaker from "../assets/solidappmakerLogo.png"
const Landing = () => {

    const onSubmitForm = (values) => {
        console.log('Success:', values);
    };
    const slider = useRef(null);
    const [open, setOpen] = useState(false);
    return (
        <>
            {/* HEADER */}
            <div className='head_container'>
                <Row className='common_container header__div' style={{ paddingTop: 15 }}>
                    <Col className='heder_space' md={24} lg={4} xs={24} sm={24}>
                        <img className='logo' src={logo} alt='logo' />
                        <div className='showMenu_btn' onClick={() => setOpen(true)} >
                            <MenuOutlined style={{ fontSize: '1.5rem' }} />
                        </div>
                    </Col>
                    <Col md={24} lg={16} xs={24} sm={24} className={'heading_detail'} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <Link className="heading" activeStyle={{ color: '#0E4A96' }} to="Home" spy={true} smooth={true} delay={50} duration={50} offset={-70}>Home</Link>
                        <Link className="heading" activeStyle={{ color: '#0E4A96' }} to="About" spy={true} smooth={true} delay={50} duration={50} offset={-70}>About us</Link>
                        <Link className="heading" activeStyle={{ color: '#0E4A96' }} to="Features" spy={true} smooth={true} delay={50} duration={50} offset={-80}>Features</Link>
                        <Link className="heading" activeStyle={{ color: '#0E4A96' }} to="Download" spy={true} smooth={true} delay={50} duration={50} offset={-70}>
                            Download
                        </Link>
                        <Link className="heading" activeStyle={{ color: '#0E4A96' }} to="Contact" spy={true} smooth={true} delay={50} duration={50} offset={-70}>
                            Contact Us
                        </Link>
                    </Col>
                    <Col md={24} lg={4} xs={24} sm={24} >
                        <div className='download_button' >
                            <a href="https://play.google.com/store/games" target='_blank'>
                                <img src={android} alt='logo' height={'100%'} />
                            </a>
                            <a href="https://www.apple.com/in/app-store/" target='_blank' >
                                <img src={ios} alt='logo' height={'100%'}/>
                            </a>
                        </div>
                    </Col>
                </Row>
            </div>
            {/* SWAP IMAGE SECTION  */}
            <section id="Home">
                <Carousel dots autoplay={false} autoplaySpeed={2000} effect="fade" >
                    <div className='background_img_container'>
                        <div className='background_img' />
                        <div className='swap_text mx-auto '>
                            <h3 className='heaing_tag'>TURKESTAN <span style={{ color: "#0E4A96" }}>LINES</span> </h3>
                            <h6 className='text_para'>Get anywhere on Time.</h6>
                            <Button className='try_button' type="primary">Try For Free</Button>
                        </div>
                    </div>
                    <div className='background_img_container'>
                        <div className='background_img' />
                        <div className='swap_text mx-auto '>
                            <h3 className='heaing_tag'>TURKESTAN <span style={{ color: "#0E4A96" }}>LINES</span> </h3>
                            <h6 className='text_para'>Get anywhere on Time.</h6>
                            <Button className='try_button' type="primary">Try For Free</Button>
                        </div>
                    </div>
                    <div className='background_img_container'>
                        <div className='background_img' />
                        <div className='swap_text mx-auto '>
                            <h3 className='heaing_tag'>TURKESTAN <span style={{ color: "#0E4A96" }}>LINES</span> </h3>
                            <h6 className='text_para'>Get anywhere on Time.</h6>
                            <Button className='try_button' type="primary">Try For Free</Button>
                        </div>
                    </div>
                </Carousel>
            </section>

            {/* SECTION ABOUT US */}
            <section id="About" className='aboutus_background aboutPadding'>
                <Row gutter={[]}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <div className='leftside_about common_container'>
                            <h1 className='title'>About Us</h1>
                            <article className=''>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporq incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis estoi nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat fer Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eurostal Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporq incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis estoi nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat fer Duis aute irure dolor in reprehenderit in voluptate velit.</article>
                            <Button className='try_button' type="primary">Contact Us</Button>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <div className="car_div">
                            <img className='rightside_player' src={Car} alt='logo' style={{ height: '100%', width: '40%' }} />
                        </div>
                    </Col>
                </Row>
            </section >

            {/* second carousel*/}
            <section className='common_container'>
                <Row gutter={[]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div >
                            <Carousel dots={true} ref={slider} autoplay={true} autoplaySpeed={2000} effect="fade">
                                <div className="">
                                    <img className='twoplayer_img' src={secondCrousel} alt='logo' />
                                </div>
                                <div className="">
                                    <img className='twoplayer_img' src={secondCrousel} alt='logo' />
                                </div>
                            </Carousel>
                            <div className='slidebutton' style={{ position: 'absolute', top: '45%', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                <div className=''>
                                    <AiOutlineLeft className='react__icon' style={{ cursor: 'pointer' }} onClick={() => slider.current.prev()} />
                                </div>
                                <div className='' >
                                    <AiOutlineRight className='react__icon' style={{ cursor: 'pointer' }} onClick={() => slider.current.next()} />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </section >

            {/* Awesome features */}
            <section className='common_container ' id="Features">
                <div className='features_section'>
                    <p className='title'>Awesome features</p>
                    <div className="" style={{ width: '70%' }}>
                        <p className='paragraph'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                    </div>
                </div>

                <Row gutter={[80, 20]} style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                    <Col lg={8} xl={8}>
                        <div className='awesome_MainDiv'>
                            <Image className='features_image' src={awsm1} alt="image" />
                            <div style={{ textAlign: 'center', width: '90%' }}>
                                <h6 style={{ marginTop: 15, fontSize: 'large' }}>Create A Free Account</h6>
                                <p className='paragraph'>Lorem ipsum dolor sit amet consetetur sadipscing elitr, sed diam</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={8} xl={8}>
                        <div className='awesome_MainDiv'>
                            <Image className='features_image' src={awsm2} alt="image" />
                            <div style={{ textAlign: 'center', width: '90%' }}>
                                <h6 style={{ marginTop: 15, fontSize: 'large' }}>Find best player</h6>
                                <p className='paragraph'>Lorem ipsum dolor sit amet consetetur sadipscing elitr, sed diam</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={8} xl={8}>
                        <div className='awesome_MainDiv'>
                            <Image className='features_image' src={awsm3} alt="image" />
                            <div style={{ textAlign: 'center', width: '90%' }}>
                                <h6 style={{ marginTop: 15, fontSize: 'large' }}>Search University</h6>
                                <p className='paragraph'>Lorem ipsum dolor sit amet consetetur sadipscing elitr, sed diam</p>
                            </div>
                        </div>
                    </Col>
                </Row>

                <div style={{ textAlign: 'center' }}>
                    <Button className='try_button' type="primary">Contact Us</Button>
                </div>
            </section >

            {/* downdld section */}
            <section className='common_container download_section' id="Download" style={{ display: 'grid', placeItems: 'center' }} >
                <div className='mobile_background'>
                    <Row gutter={[]}>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} className='dwnldContent_parent'>
                            <div className="title">
                                <img src={logo} alt="logo" width={'40%'} />
                            </div>
                            <div className='' style={{ marginTop: 25 }}>
                                <p className='title'>Download the App Now</p>
                                <p className='section_paragraph'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam  tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                                <div className='playestore_button' >
                                    <img src={android} alt='logo' />
                                    <img src={ios} alt='logo' />
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <div className=''>
                                <img className="mobile_image" src={mobile} alt='logo' />
                            </div>
                        </Col>
                    </Row >
                </div>
            </section>


            {/* contact */}
            <section style={{ minHeight: '90vh', display: 'grid', placeItems: 'center' }} className='common_container' id="Contact">
                <Row gutter={[]}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} className='contact_form'>
                        <p className='title'>Contact Us</p>
                        <p className='section_paragraph'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                        <Form name="form" onFinish={onSubmitForm} layout="vertical" className="modal_form" >
                            <Form.Item name="name" >
                                <Input className='form_style' placeholder="Name*" />
                            </Form.Item>
                            <Form.Item >
                                <Input className='form_style' placeholder="E-mail*" />
                            </Form.Item>
                            <Form.Item >
                                <Input className='messageform_style' placeholder="Message*" />
                            </Form.Item>
                            <Form.Item >
                                <Button type='ghost' className="try_button" htmlType='submit'>Send</Button>
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} className='contact_girl' style={{ marginTop: 25 }}>
                        <img style={{ width: '75%', marginLeft: '10px' }} src={avatargirl} alt='logo' />
                    </Col>
                </Row >
            </section>

            {/* footer */}
            <section >
                <div className='footer_parent common_container'>
                    {/* <div className="" style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                    </div> */}
                    <img src={logo} alt="log" style={{ width: '20%' }} />
                    <div className='footer_detail'>
                        <Link activeStyle={{ color: '#0E4A96' }}
                            className='heading'
                            to="Home" spy={true} smooth={true} delay={100} duration={100}>Home</Link>
                        <Link to="About" spy={true} smooth={true} delay={50} duration={50} className='heading' activeStyle={{ color: '#0E4A96' }}>About us</Link>
                        <Link className="heading" activeStyle={{ color: '#0E4A96' }} to="Features" spy={true} smooth={true} delay={50} duration={50} offset={-80}>Features</Link>
                        <Link className="heading" activeStyle={{ color: '#0E4A96' }} to="Download" spy={true} smooth={true} delay={50} duration={50} offset={-100}>
                            Download
                        </Link>
                        <Link className="heading" activeStyle={{ color: '#0E4A96' }} to="Contact" spy={true} smooth={true} delay={50} duration={50}>
                            Contact Us
                        </Link>
                    </div>
                </div>
                <div className='bottom'>
                    <article > &copy; 2023-All Rights Reserved - Turkestan Lines | Designed & Developed by <a href="https://solidappmaker.com/" target='_blank' className='solid_link'><img src={solidAppMaker} alt="logo" width={'6%'} /></a> </article>
                </div>
            </section>

            {/*   for md screen */}
            <Drawer placement="right" onClose={() => setOpen(false)} open={open}>
                <Link className="heading" activeStyle={{ color: '#0E4A96' }} to="Home" spy={true} smooth={true} delay={50} duration={50} offset={-70}>Home</Link>
                <Link className="heading" activeStyle={{ color: '#0E4A96' }} to="About" spy={true} smooth={true} delay={50} duration={50} offset={-70}>About us</Link>
                <Link className="heading" activeStyle={{ color: '#0E4A96' }} to="Features" spy={true} smooth={true} delay={50} duration={50} offset={-70}>Features</Link>
                <Link className="heading" activeStyle={{ color: '#0E4A96' }} to="Download" spy={true} smooth={true} delay={50} duration={50} offset={200}>
                    Download
                </Link>
                <Link className="heading" activeStyle={{ color: '#0E4A96' }} to="Contact" spy={true} smooth={true} delay={50} duration={50} offset={-70}>
                    Contact Us
                </Link>
                <div className='download_butt'>
                    <div className='my-2'>
                        <Image height={50} preview={false} src={ios} alt='logo' />
                    </div>
                    <div>
                        <Image height={50} preview={false} src={android} alt='logo' />
                    </div>
                </div>
            </Drawer>
        </>
    )
}

export default Landing